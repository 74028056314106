<script setup>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'

const props = defineProps({
  collections: {
    type: Array,
    required: true,
    default: [],
  },
})

const breakpoints = {
  1: {
    itemsToShow: 1,
    itemsToScroll: 1,
    snapAlign: "start",
  },
}

</script>

<template>
  <div>
    <carousel
        class="home-carousel"
        :items-to-show="1"
        :items-to-scroll="1"
        snap-align="start"
        :breakpoints="breakpoints"
        :wrap-around="true"
        :autoplay="5000"
    >
      <template v-for="collection in props.collections" :key="collection">
        <template v-for="asset in collection.assets" :key="asset">
          <Slide>
            <nuxt-img
                class="image"
                :src="asset.original.url"
                sizes="xs:640px sm:769px md:1024px lg:1216px xl:1408px xxl:2048px"
                draggable="false"
            />
          </Slide>
        </template>
      </template>

      <template #addons>
        <navigation/>
        <Pagination/>
      </template>
    </carousel>
  </div>
</template>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins";
@import 'vue3-carousel/dist/carousel.css';
@import "scss/variables";

.carousel {
  max-width: 100vw;

  .carousel__slide {
    width: 100%;
    min-height: 400px;
    max-height: 55vh;
    padding: 0;
    background-color: $pink;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
</style>

<style lang="scss">
@import "bulma/sass/utilities/mixins";
@import "scss/variables";

.carousel.home-carousel {
  .carousel__viewport {
  }

  .carousel__prev,
  .carousel__next {
    background: radial-gradient(circle, rgb(255, 255, 255, 0.1) 20%, rgba(0, 0, 0, 0) 80%);
    border-radius: 10px;

    svg {
      scale: 2.5;
      transform: scaleX(0.8);
      fill: $blue;
    }

    @include touch {
      display: none;
    }
  }

  .carousel__pagination {
    .carousel__pagination-button::after {
      border-radius: 100%;
      height: 10px;
      width: 10px;
      background-color: $pink;
    }

    .carousel__pagination-button:hover::after,
    .carousel__pagination-button--active::after {
      background-color: findDarkColor($pink);
    }
  }
}
</style>