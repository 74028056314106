<script setup>

import ProductCarousel from "../components/product/ProductCarousel.vue";
import {useRuntimeConfig} from "nuxt/app";
import BlogCard from "../components/blogs/BlogCard.vue";
import {usePageTitle} from "../composables/pageTitle";
import HomeCarousel from "~/components/HomeCarousel.vue";
import NewsletterSubscribe from "~/components/NewsletterSubscribe.vue";

definePageMeta({layout: 'main'})

const config = useRuntimeConfig()

const {data} = useAsyncData('blogsListHome', () => queryContent()
    .where({category: 'About Dreaming Unicorns'})
    .sort({'dates.published': -1})
    .limit(4)
    .find()
)

const {data: dataProducts, pending, error} = useAsyncData(
    'product-collection',
    () => $fetch('/api/products-collections.json', {baseURL: config.public.staticBackendUrl}),
    {server: true}
)

useHead({
  title: usePageTitle('Welcome'),
})

useServerSeoMeta({
  description: 'Welcome to Dreaming Unicorns, where magic dances with elegance and playfulness. Discover ' +
      'our enchanting collection of wall decals, designed to add a sprinkle of magic to your space.',
})

</script>

<template>
  <section class="hero">
    <div class="hero-body">
      <HomeCarousel :collections="dataProducts?.collections" />
    </div>
  </section>
  <section class="section">
    <div class="container">
      <h1 class="title">
        Latest Release!
      </h1>

      <div class="is-hidden-mobile">
        <p>
          Discover a world where magic dances with elegance and playfulness in our Dreaming Unicorns collection.
          From majestic unicorns to vibrant rainbows and shimmering stars, each decal adds a sprinkle of enchantment
          to any space.
        </p>
        <p>
          Not only are they a visual delight, but our decals also practical. Easily removable, they empower
          you to refresh and reimagine your surroundings time and again. Dive into this whimsical world and let
          your space shine anew.
        </p>
      </div>
      <div class="is-hidden-tablet">
        <p>
          Dive into the magic of our Dreaming Unicorns collection. Majestic unicorns, vibrant rainbows, and stars
          bring enchantment to every space. Our easily removable decals combine beauty with practicality,
          letting you reimagine and shine anew.
        </p>
      </div>

      <div class="pt-5 pb-6">
        <ProductCarousel :products="dataProducts?.products"/>
      </div>

      <div class="buttons is-justify-content-center">
        <nuxt-link
            :to="{name: 'shop'}"
            class="button is-primary is-outlined is-medium"
        >
          Shop All Products
        </nuxt-link>
      </div>

      <newsletter-subscribe />

      <h1 class="title mt-6">
        Fun Unicorn "Facts"
      </h1>

      <div class="is-hidden-mobile">
        <p>
          Unicorns, with their air of mystery, have mesmerised us since ancient times. These mystical creatures,
          paired with vibrant rainbows, evoke tales of hope and magic, turning our skies into a canvas of dreams.
        </p>
        <p>
          As dusk falls, legend whispers that each star is a unicorn's dream. The sight of a shooting star? A
          nudge to make a wish. And while a unicorn's beauty is captivating, it's their legendary horn that
          truly fascinates, believed to harness healing powers. With our decals, your walls tell stories of
          dreams, magic, and a hint of ancient lore.
        </p>
      </div>
      <div class="is-hidden-tablet">
        <p>
          Unicorns and vibrant rainbows have enchanted us for ages, painting our skies with dreams. Legends say
          each star mirrors a unicorn's dream, and their horns harness healing. Our decals infuse walls with
          this magic and ancient lore.
        </p>
      </div>

      <div class="blogs mt-6">
        <div class="blog" v-for="blog in data">
          <BlogCard :blog="blog"/>
        </div>
      </div>


      <div class="buttons is-justify-content-center">
        <nuxt-link
            :to="{name: 'blog'}"
            class="button is-primary is-outlined is-medium"
        >
          All Articles
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

p {
  margin-bottom: 1rem;
}

.blogs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;
  margin-bottom: 2.5rem;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include fullhd {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.hero-body {
  padding: 0;
  margin: 0;
}
</style>