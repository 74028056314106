<script setup>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import ProductCard from "./ProductCard.vue";

const props = defineProps({
  products: {
    type: Array,
    required: true,
    default: [],
  },
})

const breakpoints = {
  1: {
    itemsToShow: 1.125,
    itemsToScroll: 1,
    snapAlign: "start",
  },
  768: {
    itemsToShow: 2,
    itemsToScroll: 1,
    snapAlign: "start",
  },
  1216: {
    itemsToShow: 3,
    itemsToScroll: 1,
    snapAlign: "start",
  },
}


const productCarousel = ref(null)

function next() {
  productCarousel.value.next()
}

function prev() {
  productCarousel.value.prev()
}
</script>

<template>
  <div class="outer-carousel">
    <div class="carousel-navigation">
      <button @click="prev">
        <svg class="carousel__icon" viewBox="0 0 30 30" role="img" aria-label="Arrow pointing to the left">
          <title>Arrow pointing to the left</title>
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
        </svg>
      </button>
      <button @click="next">
        <svg class="carousel__icon" viewBox="0 0 30 30" role="img" aria-label="Arrow pointing to the right">
          <title>Arrow pointing to the right</title>
          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
        </svg>
      </button>

    </div>
    <carousel
        ref="productCarousel"
        class="product-carousel"
        :items-to-show="1.125"
        :items-to-scroll="1"
        snap-align="start"
        :breakpoints="breakpoints"
        :wrap-around="true"
    >
      <slide v-for="product in products" :key="product.id">
        <div class="carousel__item">
          <ProductCard :product="product" :show-description="false"/>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins";
@import 'vue3-carousel/dist/carousel.css';
@import "scss/variables";

.outer-carousel {
  &:hover {
    .carousel-navigation {
      opacity: 0.7;
    }
  }

  @include tablet-only {
    margin-top: 24px;
  }

  .carousel-navigation {
    position: absolute;
    right: 0;
    margin-top: -16px;
    z-index: 1;
    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    @include mobile {
      display: none;
    }

    button {
      margin-left: 8px;
      cursor: pointer;

      border-radius: 12px;
      height: 33px;
      width: 33px;
      padding: 0;
      border: 1px solid #c0c0c0;
      background: none;
      box-shadow: 0 0 0 1px rgba(192, 192, 192, 0.51), inset 0 0 0 1px $beige;
      transition: scale 0.1s ease, border 0.2s ease;

      .carousel__icon {
        height: 37px;
        width: 39px;
        fill: grey;
        transition: fill 0.3s ease-in-out;
      }

      &:hover {
        scale: 1.1;
        border: 1px solid $beige;
        box-shadow: 0 0 0 1px #838383;

        .carousel__icon {
          fill: #484848;
        }
      }

      &:active {
        scale: 1;
      }
    }
  }
}


.carousel {
  margin: -32px -16px;
  overflow: hidden;
  max-width: 100vw;
  max-height: 888px;

  @include widescreen {
    max-height: 555px;
  }

  @include mobile {
    margin: -32px -25px;
  }

  .carousel__item {
    width: 100%;
  }

  .carousel__slide {
    padding: 32px 16px;

    @include mobile {
      padding: 32px 0 32px 25px;
    }
  }
}
</style>

<style lang="scss">
@import "bulma/sass/utilities/mixins";

.carousel.product-carousel {
  .carousel__viewport {
  }

  .carousel__prev {
    left: -35px;
    height: 150px;

    @include touch {
      display: none;
    }
  }

  .carousel__next {
    right: -35px;
    height: 150px;

    @include touch {
      display: none;
    }
  }
}
</style>